// import { parseTime } from '@/plugins/lib/modules/date'
import PlanSelector from '@/components/PlanSelector/PlanSelector.vue';
export var searchOption = [
{
  kname: 'goodsName',
  label: '货品：',
  component: {
    name: 'el-input',
    props: {
      clearable: true,
      placeholder: '货品编号、货品名称' } } },



{
  kname: 'secondTypeId',
  label: '货品分类：',
  component: {
    name: 'selector',
    props: {
      type: 'goods',
      clearable: true,
      cascader: true } } },



{
  kname: 'goodsLocationNo',
  label: '位置：',
  component: {
    name: PlanSelector,
    props: {
      "class": 'w180',
      clearable: true,
      showIcon: false } } },



{
  kname: 'createTime',
  label: '创建时间：',
  component: {
    name: 'c-date-picker' } },


{
  kname: 'deptId',
  label: '所属部门：',
  component: {
    name: 'selector',
    props: {
      type: 'depts',
      "class": 'w180',
      clearable: true,
      filterable: true } } },



{
  kname: 'operationUser',
  label: '操作人员：',
  component: {
    name: 'el-input',
    props: {
      "class": 'w180',
      clearable: true,
      placeholder: '请输入' } } },



{
  kname: 'containerId',
  label: '货箱：',
  component: {
    name: 'selector',
    props: {
      type: 'syscontainers',
      "class": 'w180',
      clearable: true,
      filterable: true } } }];




export var columns = function columns(ctx) {return [
  // {
  //   type: 'selection'
  // },
  {
    label: '货品编号',
    kname: 'goodsNo',
    width: 100 },

  {
    label: '货品名称',
    kname: 'goodsName' },

  {
    label: '分类',
    kname: 'goodsTypeName',
    showOverflowTooltip: true },

  {
    label: '规格型号',
    kname: 'model' },

  {
    label: '位置',
    kname: 'goodsLocationNo' },

  {
    label: '所属部门',
    kname: 'deptName' },

  {
    label: '创建时间',
    kname: 'createTime',
    width: 140 },

  {
    label: '操作人',
    kname: 'operationUser' },

  {
    label: '货箱',
    kname: 'containerName' },

  {
    label: '操作',
    kname: 'btns',
    width: 100 }];};