import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3d6148f0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d6148f0')) {
      api.createRecord('3d6148f0', component.options)
    } else {
      api.reload('3d6148f0', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=3d6148f0&", function () {
      api.rerender('3d6148f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/plugins/components/plan/demo/list/index.vue"
export default component.exports