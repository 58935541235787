var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "c-box",
        {
          attrs: { slot: "header", flex: "row", justify: "space-between" },
          slot: "header"
        },
        [
          _c(
            "c-search",
            {
              attrs: { option: _vm.searchOption },
              on: { submit: _vm.getData },
              model: {
                value: _vm.query,
                callback: function($$v) {
                  _vm.query = $$v
                },
                expression: "query"
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        "native-type": "submit",
                        size: "small",
                        type: "primary"
                      }
                    },
                    [_vm._v(" 查询 ")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleReset }
                    },
                    [_vm._v(" 重置 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "c-box",
        { attrs: { slot: "header", padding: "0 0 15px" }, slot: "header" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v(" 新增 ")]
          ),
          _c(
            "ExportBtn",
            {
              attrs: {
                downloadUrl: "/sysgoodss/download",
                uploadUrl: "/sysgoodss/import",
                fileType: "xlsx",
                fileName: "货品模板"
              }
            },
            [_vm._v(" 导入 ")]
          )
        ],
        1
      ),
      _c("c-table", {
        attrs: {
          columns: _vm.columns,
          data: _vm.list,
          border: "",
          size: "mini",
          stripe: ""
        },
        on: { "selection-change": _vm.handleSelection },
        scopedSlots: _vm._u([
          {
            key: "goodsLocationNo",
            fn: function(scope) {
              return [
                _c("PlanLocationl", {
                  attrs: {
                    align: "center",
                    justify: "center",
                    value: scope.row.goodsLocationNo
                  },
                  on: { click: _vm.handleLocation }
                })
              ]
            }
          },
          {
            key: "btns",
            fn: function(scope) {
              return [
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary", underline: false },
                    on: {
                      click: function($event) {
                        return _vm.handleEdit(scope.row)
                      }
                    }
                  },
                  [_vm._v(" 编辑 ")]
                ),
                _c(
                  "el-link",
                  {
                    attrs: { type: "success", underline: false },
                    on: {
                      click: function($event) {
                        return _vm.handleDetail(scope.row)
                      }
                    }
                  },
                  [_vm._v(" 详情 ")]
                )
              ]
            }
          }
        ])
      }),
      _c("c-pagination", {
        attrs: { slot: "footer", page: _vm.page, search: _vm.getData },
        slot: "footer"
      }),
      _c("AddDialog", { ref: "AddDialog", on: { refresh: _vm.getData } }),
      _c("DetailDialog", {
        ref: "DetailDialog",
        on: { showLocation: _vm.handleLocation }
      }),
      _c("PlanSelector", {
        ref: "PlanSelector",
        attrs: {
          title: "查看位置",
          value: _vm.goodsLocationNo,
          editable: false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }